<template>
  <Content>
    <template #contentTitle>
      {{ $t("menu.user_account_list") }}
    </template>
    <template v-slot:contentBody="wrap">
      <div class="mb-2">
        <a-row type="flex"
               justify="space-between"
               align="bottom">
          <a-col>
            <a-form layout="inline">
              <a-form-item class="mt-1">
                 <a-input :placeholder="$t('common.query')"
                         allow-clear
                         v-model:value="tableData.searchData.searchKey"
                         style="width: 250px"
                         @keyup.enter="handleSearch"
                         ></a-input>
              </a-form-item>
              <a-form-item class="mt-1">
                <a-button type="primary"
                          @click="handleSearch">{{ $t('common.query') }}</a-button>
              </a-form-item>

            </a-form>
          </a-col>
          <a-col>
             <a-button type="primary" ghost @click="handleOpenCreateModal">{{ $t('common.create') }}</a-button>
          </a-col>
        </a-row>
      </div>
      <div>
      <a-table
        :columns="columns"
        size="small"
        :data-source="tableData.tableList"
        :pagination="false"
        :scroll="{ x: 500, y: wrap.contentHeight - 140 }"
        :rowKey="
          (record, index) => {
            return index;
          }
        "
        :loading="tableData.loading"
      >
        <template #userName="{ record }">
          <div>{{ record.userName }}{{record.isManager?"("+$t("account.main_account")+")":""}}</div>
          <div>{{ record.nickName }}</div>
        </template>
        <template #countryName="{ record }">
          {{ getCountryName(record.countryCnName, record.countryEnName) }}
        </template>
        <template #createTime="{ record }">
          {{ $filters.utcToCurrentTime(record.createTime) }}
        </template>
        <template #lastLoginTime="{ record }">
          {{ $filters.utcToCurrentTime(record.lastLoginTime) }}
        </template>
        <template #operation="{ record }">
          <a-dropdown-button>
             <router-link
              :to="{
                name: 'user_account_details',
                params: { id: record.userId },
              }"
            >
              {{ $t("account.details") }}
            </router-link>
            <template #overlay>
              <a-menu @click="handleClickOperatMenu">
                <a-menu-item :key="4" :record="record" :disabled="record.isManager">
                  {{ $t("account.user_roles") }}
                </a-menu-item>
                <a-menu-item :key="3" :record="record" :disabled="record.isManager">
                  {{ $t("account.remove_user") }}
                </a-menu-item>
              </a-menu>
            </template>
          </a-dropdown-button>
        </template>
      </a-table>
      </div>
      <UserRolesModal ref="userRolesModalRef" @refresh="handleSearch" />
      <CreateUserModal ref="createUserModalRef" @refresh="handleSearch" />
    </template>
    <template #contentFooter>
      <c-pager
        class="text-center"
        @handlePage="handlePage"
        :page-data="tableData.pageData"
      />
    </template>
  </Content>
</template>

<script>
import { defineComponent, onMounted, reactive, toRaw, toRefs, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n/index";
import {
  Row,
  Col,
  Tabs,
  Table,
  Select,
  Dropdown,
  Menu,
  Button,
  Modal,
  message,
  Input,
  Form
} from "ant-design-vue";
import Content from "@/views/components/Content";
import CPager from "@/views/components/CPager";
import UserRolesModal from './components/UserRolesModal.vue';
import CreateUserModal from './components/CreateUserModal.vue';
import {
  getShopUserListPaged,
  cullOutUserFromShop,
} from "../../../api/modules/user/account/index";
import { getName } from "@/utils/general";

export default defineComponent({
  name: 'customer_account_list',
  components: {
    ARow: Row,
    ACol: Col,
    ATabs: Tabs,
    ATabPane: Tabs.TabPane,
    AInput:Input,
    ATable: Table,
    ASelect: Select,
    ASelectOption: Select.Option,
    ADropdown: Dropdown,
    ADropdownButton: Dropdown.Button,
    AMenu: Menu,
    AMenuItem: Menu.Item,
    AButton: Button,
    Content,
    CPager,
    UserRolesModal,
    CreateUserModal,
    AForm: Form,
    AFormItem: Form.Item,
    Modal:Modal
  },
  setup() {
    const { getters } = useStore();
    const vueI18n = useI18n({ useScope: "global" });

    const userRolesModalRef = ref(null);
    const createUserModalRef = ref(null);

    const state = reactive({
      companyList: [],
      searchData: {
        searchKey:""
      },
      tableData: {
        tableList: [],
        loading: false,
        searchData:{
          searchKey:""
        },
        pageData: {
          currentIndex: 1,
          skipCount: 0,
          totalCount: 0,
          maxResultCount: 10,
        },
      },
    });

    const columns = [
      {
        title: () => vueI18n.t("account.account_name"),
        width: 200,
        slots: {
          customRender: "userName",
        },
      },
      {
        title: () => vueI18n.t("account.country"),
        width: 150,
        slots: {
          customRender: "countryName",
        },
      },
      {
        dataIndex: "timezone",
        title: () => vueI18n.t("account.timezone"),
        width: 150,
      },
      {
        title: () => vueI18n.t("account.registration_date"),
        width: 150,
        slots: {
          customRender: "createTime",
        },
      },
      {
        title: () => vueI18n.t("account.last_login_time"),
        width: 150,
        slots: {
          customRender: "lastLoginTime",
        },
      },
      {
        title: () => vueI18n.t("common.operation"),
        width: 150,
        fixed: 'right',
        slots: {
          customRender: "operation",
        },
      },
    ];

    const getPageList = () => {
      state.tableData.loading = true;
      // 列表
      const data = Object.assign(
        {},
        state.tableData.pageData,
        state.tableData.searchData
      );
      getShopUserListPaged(data)
        .then((res) => {
          state.tableData.loading = false;
          let { items = [], totalCount = 0 } = res.result;
          state.tableData.pageData.totalCount = parseInt(totalCount);
          state.tableData.tableList = items;
        })
        .catch(() => {
          state.tableData.loading = false;
        });
    };

    const handlePage = (pageData) => {
      state.tableData.pageData.skipCount = pageData.skipCount;
      state.tableData.pageData.maxResultCount = pageData.maxResultCount;
      getPageList();
    };

    const handleSearch = () => {
      state.tableData.pageData.currentIndex = 1;
      state.tableData.pageData.skipCount = 0;
      getPageList();
    };

    // 删除
    const handleDelete = (record) => {
      Modal.confirm({
        title: vueI18n.t('account.remove_user'),
        content: vueI18n.t('account.remove_user_tip'),
        onOk() {
          return cullOutUserFromShop({ userId: record.userId })
          .then((res) => {
            message.success(vueI18n.t("common.succeed"));
          }).finally(()=>{
            handleSearch()
          });
        },
      });
    };

    const handleClickOperatMenu = (val) => {
      let record = val.item.record;
      switch (val.key) {
        case 3:
          handleDelete(record);
          break;
        case 4:
          userRolesModalRef.value.init(record);
          break;
        default:
          break;
      }
    };

    const getCountryName = (cnName, enName) => {
      return getName({ cnName, enName }, getters.language);
    };

    const handleOpenCreateModal = (record)=>{
      createUserModalRef.value.init(record);
    }

    onMounted(()=>{
      handleSearch()
    });

    return {
      ...toRefs(state),
      columns,
      userRolesModalRef,
      createUserModalRef,
      handleSearch,
      handlePage,
      getPageList,
      getCountryName,
      handleClickOperatMenu,
      handleOpenCreateModal
    };
  },
});
</script>
