<template>
  <a-modal v-model:visible="visible" :title="$t('account.create_account')" :confirm-loading="confirmLoading" @ok="handleConfirm">
    <a-spin :spinning="loading">
      <a-form ref="crateAccountRef" :model="formState" :rules="rules" :label-col="{ style: { width: '100px' } }" :wrapper-col="{ span: 14 }">
        <a-form-item :label="$t('account.phone_number')" name="phoneNumber">
            <a-input
              v-model:value="formState.phoneNumber"
              :placeholder="$t('account.phone_number')"
            ></a-input>
          </a-form-item>
          <a-form-item :label="$t('account.nick_name')" name="nickName">
            <a-input
              v-model:value="formState.nickName"
              :placeholder="$t('account.nick_name')"
            ></a-input>
          </a-form-item>
          <a-form-item :label="$t('account.password')" name="password">
            <label>{{ $t('account.password_default_tip') }}</label>
          </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs } from 'vue';
import { Modal, Form, Select, Spin, message,Input } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { createShopUserAccount } from '../../../../api/modules/user/account';
import {
  isValidGenericPhoneNumber
} from "../../../../utils/general";

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    AInput:Input,
    ASpin: Spin,
  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const crateAccountRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      info: null,
      formState: {
        phoneNumber: "",
        nickName:""
      }
    })

    const rules = {
      phoneNumber: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('account.phone_number')]),
      },
      nickName: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('account.nick_name')]),
      }
    }


    const handleConfirm = async () => {
      try {
        await crateAccountRef.value.validate()
        if (state.formState.phoneNumber) {
          if (!isValidGenericPhoneNumber(state.formState.phoneNumber)) {
            message.error(vueI18n.t("common.p0_incorrect_format", [vueI18n.t('account.phone_number')]));
            return false
          }
        }
        state.confirmLoading = true
        const data = {
          phoneNumber: state.formState.phoneNumber,
          nickName: state.formState.nickName
        }
        await createShopUserAccount(data)
        message.success(vueI18n.t('common.succeed'))
        state.visible = false
        emit('refresh')
      } catch (error) {
        
      } finally {
        state.confirmLoading = false
      }
    }

    const init = async (info) => {
      state.info = info
      state.visible = true
      nextTick(() => {
        crateAccountRef.value.resetFields()
      })
      state.selectLoading = true
      state.selectLoading = false
    }

    return {
      ...toRefs(state),
      rules,
      crateAccountRef,
      handleConfirm,
      init,
    }
  },
})
</script>
