<template>
  <a-modal v-model:visible="visible" :title="$t('account.user_roles')" :confirm-loading="confirmLoading" @ok="handleConfirm">
    <a-spin :spinning="loading">
      <a-form ref="roleRef" :model="formState" :rules="rules" :label-col="{ style: { width: '100px' } }" :wrapper-col="{ span: 14 }">
        <a-form-item v-if="info" :label="$t('account.account_name')">
          <div>{{ info.userName }}</div>
          <div>{{ info.mail }}</div>
        </a-form-item>
        <a-form-item :label="$t('account.role')" name="roleIds">
          <a-select v-model:value="formState.roleIds" mode="multiple" :placeholder="$t('common.please_select')">
            <a-select-option
              v-for="(item, index) in roleList"
              :key="index"
              :value="item.roleId"
              :title="item.roleName"
            >{{ item.roleName }}</a-select-option>
          </a-select>
        </a-form-item>
      </a-form>
    </a-spin>
  </a-modal>
</template>

<script>
import { defineComponent, nextTick, reactive, ref, toRefs } from 'vue';
import { Modal, Form, Select, Spin, message } from 'ant-design-vue';
import { useI18n } from "vue-i18n/index";
import { getShopRoles, getRoleIds, updateShopUserRole } 
from '../../../../api/modules/user/account';

export default defineComponent({
  components: {
    AModal: Modal,
    AForm: Form,
    AFormItem: Form.Item,
    ASelect: Select,
    ASelectOption: Select.Option,
    ASpin: Spin,

  },
  setup(props, { emit }) {
    const vueI18n = useI18n({ useScope: "global" });

    const roleRef = ref(null);

    const state = reactive({
      visible: false,
      loading: false,
      confirmLoading: false,
      info: null,
      roleList: [],
      formState: {
        roleIds: []
      }
    })

    const rules = {
      roleIds: {
        required: true,
        message: vueI18n.t('common.p0_is_required', [vueI18n.t('account.role')]),
      }
    }

    const handleConfirm = ()=>{
      handleSave();
    }
    const handleSave = async () => {
      try {
        await roleRef.value.validate()
        state.confirmLoading = true
        const data = {
          userId: state.info.userId,
          roleIds: state.formState.roleIds
        }
        await updateShopUserRole(data)
        message.success(vueI18n.t('common.succeed'))
        state.visible = false
        emit('refresh')
      } catch (error) {
        
      } finally {
        state.confirmLoading = false
      }
    }

    const init = async (info) => {
      state.info = info
      state.visible = true
      nextTick(() => {
        roleRef.value.resetFields()
      })
      state.selectLoading = true
      try {
        // roleTypeId: 1.店铺角色,2.仓库角色,4.管理员角色,8.合作伙伴角色
        let { result: roleIds } = await getRoleIds({ userId: info.userId })
        state.formState.roleIds = roleIds
        let { result } = await getShopRoles()
        state.roleList = result
      } catch (error) {
        
      } finally {
        state.selectLoading = false
      }
    }

    return {
      ...toRefs(state),
      rules,
      roleRef,
      handleConfirm,
      init,
    }
  },
})
</script>
